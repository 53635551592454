// libraries
import React, { useState, useEffect } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

// assets
import arrow from '../../../../assets/img/projects/Arrow1.svg'
import bank1 from '../../../../assets/img/banks/kapital.svg';
import bank2 from '../../../../assets/img/banks/atb.svg';
import bank3 from '../../../../assets/img/banks/vtb.svg';
import bank4 from '../../../../assets/img/banks/abb.svg';
import bank5 from '../../../../assets/img/banks/turan.svg';
import bank6 from '../../../../assets/img/banks/pashabank.svg';
import bank7 from '../../../../assets/img/banks/rabita.png';
import bank9 from '../../../../assets/img/banks/bob.svg';
import bank10 from '../../../../assets/img/banks/br.svg';
import bank11 from '../../../../assets/img/banks/btb.svg';
import bank12 from '../../../../assets/img/banks/afb.png';
import bank13 from '../../../../assets/img/banks/express.png';
import c1 from '../../../../assets/img/projects/c1.svg'
import c2 from '../../../../assets/img/projects/c2.svg'
import c3 from '../../../../assets/img/projects/c3.svg'
import close from '../../../../assets/img/projects/close.svg'

// functions & components
import { apiClient } from '../../../core/api_client';
import l from '../../../core/localize';
import ProjectMainPage from '../project/ui/pages/main_page';
import ProjectGallerySwiper from '../components/ProjectGallerySwiper'
import { Routing } from '../../../core/routing';
import { SwiperSlide } from "swiper/react";

// styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";
import "swiper/css/navigation";
import ResizableImg from '../../../components/ResizableImg';


const ProjectPage = () => {
    const { projectId } = useParams();

    const [activeGalleryTab, setActiveGalleryTab] = useState(0);
    const [showPhotoInfo, setShowPhotoInfo] = useState(null);

    const [activeProject, setActiveProject] = useState({});
    const [stats, setStats] = useState({});
    const [category, setCategory] = useState(undefined);
    const [exterier, setExterier] = useState([]);
    const [interier, setInterier] = useState([]);
    const [video, setVideo] = useState([]);
    const { t, i18n } = useTranslation()
    const currentLang = i18n.language.slice(0, 2)
    const [isLoading, setLoading] = useState(true)

    useEffect(() => {
        apiClient.get('/projectPlans/' + projectId).then(res => {
            setActiveProject(res.data);
            setStats(JSON.parse(res.data.stats));
        });
        apiClient.get(`multimediaProjects?projectPlanId=${projectId}`).then(res => {
            const data = res.data;
            setExterier(data.filter(x => x.type === 'exterior'));
            setInterier(data.filter(x => x.type === 'interior'));
            setVideo(data.filter(x => x.type === 'video'));
        });
    }, [projectId]);

    useEffect(() => {
        if (activeProject?.project_category_id) {
            apiClient.get('/projectCategories/' + activeProject.project_category_id).then(res => {
                console.log("activeProject", activeProject)
                setCategory(res.data);
                setLoading(false)
            });
        }

        console.log(exterier)
    }, [activeProject]);

    if (activeProject == null) return <></>

    return (
        <>
            {isLoading &&
                <div className='d-flex justify-content-center' style={{ height: window.innerHeight }}><div className="lds-roller" style={{ top: 200 }}><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div></div>}
            {activeProject &&
                <div className="single-project block-bg1">
                    <div className='breadcrumb page-link my-0 pt-5'>
                        <div className='line'></div>
                        <div className='container d-flex justify-content-center'>
                            <div className='bc-items px-4 justify-content-center'>
                                {category &&
                                    <>
                                        <Link to="/layiheler" className='bc-button'>{t('projects:projects')}</Link>
                                        <Link to={"/layiheler/" + activeProject.project_category_id} className={'bc-button ' + category.style}>{l(category, "name", currentLang)}</Link>
                                        <Link to={"/layihe/" + projectId} className='bc-button bc-button-active' style={{ maxWidth: "unset" }}>{l(activeProject, "name", currentLang)}</Link>
                                    </>
                                }
                            </div>
                        </div>
                    </div>

                    {/* {activeProject.banner_file &&
                <div className='project-banner' style={{ backgroundImage: `url(${Routing.generateFileRoute(activeProject.banner_file)})`, height: "600px" }}></div>
            } */}

                    {activeProject.banner_file &&
                        <img className='project-banner w-100' style={{ objectFit: "cover", maxHeight: "600px" }} src={Routing.generateFileRoute(activeProject.banner_file)} alt='' />
                    }

                    <div className="nav nav-tabs mb-2 pb-1 col-12 m-0 row">
                        <small id='tab1' className="nav-item col-md-4 col-4">
                            <a className="nav-link" href="#etrafli-melumat">Ətraflı məlumat</a>
                        </small>
                        {(activeProject.prices_file || activeProject.main_plan_file) &&
                            <small id='tab2' className="nav-item col-md-4 col-4">
                                <a className="nav-link" href="#menzil-planlari">Mənzil planları</a>
                            </small>}
                        <small id='tab3' className="nav-item col-md-4 col-4">
                            <a className="nav-link" href="#multimedia">Multimedia</a>
                        </small>
                    </div>
                    <div id="etrafli-melumat" className='buttons-2 d-flex row justify-content-center row'>
                        {
                            activeProject.main_plan_file &&
                            <a className='col-6 col-sm-4 col-md-3 col-lg-2 p-0 d-flex justify-content-center' href={Routing.generateFileRoute(activeProject.main_plan_file)} target='_blank' rel="noreferrer" alt="Ərazİnİn baş planı">
                                <button className=' my-2'>Ərazİnİn baş planı</button>
                            </a>
                        }
                        {
                            activeProject.repair_type_video &&
                            <a className='col-6 col-sm-4 col-md-3 col-lg-2 p-0 d-flex justify-content-center' href={activeProject.repair_type_video} target='_blank' rel="noreferrer" alt="Ərazİnİn baş planı">
                                <button className=' my-2'>təmİr növlərİ</button>
                            </a>
                        }
                        {
                            activeProject.prices_file &&
                            <a className='col-6 col-sm-4 col-md-3 col-lg-2 p-0 d-flex justify-content-center' href={Routing.generateFileRoute(activeProject.prices_file)} target='_blank' rel="noreferrer" alt="Ərazİnİn baş planı">
                                <button className=' my-2 '>mənzİllərİn satış qİymətİ</button>
                            </a>
                        }
                    </div>
                    <div className='block-bg-white mt-3'>
                        <div className="row justify-content-center py-4 mb-lg-5 mb-0 w-100 px-md-5 px-3 mx-auto">
                            <div className='project-description col ps-md-5 pe-md-4 text-justify my-md-0 my-3 ql-editor' style={{ whiteSpace: "pre-wrap" }} dangerouslySetInnerHTML={{ __html: l(activeProject, "description", currentLang) }}>

                            </div>
                        </div>

                        <div className='row justify-content-center my-2 me-md-5'>
                            <div className='col-sm-3 col-12 mx-3 d-flex align-items-center justify-content-center my-3 my-lg-0 mx-sm-4'>
                                <img src={c1} alt="" />
                                <div className='ms-3'>
                                    <h5 className='t-color5'>Binaların ümumi sayı</h5>
                                    <h4 className='highlight fw-700'>{stats.totalBuildingsCount}</h4>
                                </div>
                            </div>
                            <div className='col-sm-3 col-12 mx-3 d-flex align-items-center justify-content-center my-3 my-lg-0'>
                                <img src={c2} alt="" />
                                <div className='ms-3'>
                                    <h5 className='t-color5'>Binaların mərtəbəliyi</h5>
                                    <h4 className='highlight fw-700'>{
                                        stats.buildingMinFloorCount === stats.buildingMaxFloorCount ?
                                            stats.buildingMinFloorCount :
                                            stats.buildingMinFloorCount + " - " + stats.buildingMaxFloorCount}</h4>
                                </div>
                            </div>
                            <div className='col-sm-3 col-12 mx-3 d-flex align-items-center justify-content-center my-3 my-lg-0'>
                                <img src={c3} alt="" />
                                <div className='ms-3'>
                                    <h5 className='t-color5'>Mənzillərin ümumi sayı</h5>
                                    <h4 className='highlight fw-700'>{stats.totalApartmentsCount}</h4>
                                </div>
                            </div>
                        </div>
                        <div id="menzil-planlari" className='room-details row justify-content-center py-5'>
                            {stats.apartmentsInfo && stats.apartmentsInfo.sort((a, b) => a.index - b.index).map(apartmentInfo =>
                                <div className='col-sm-5 col-md-4 col-lg-3 col-12 mx-3 text-center my-4 my-lg-4' key={apartmentInfo.index}>
                                    <small className='t-color5 fw-bolder'>{apartmentInfo.type}</small>
                                    <p className='my-2' style={{ whiteSpace: "nowrap" }}>
                                        {apartmentInfo.sizes.sort((a, b) => a.index - b.index).map(size =>
                                            (size.min !== 0 || size.max !== 0) &&
                                            <React.Fragment key={size.index}>
                                                {size.min === size.max ?
                                                    size.min + " m²" + " (" + size.type + ")" :
                                                    size.min + " m² - " + size.max + " m²" + " (" + size.type + ")"}
                                                <br />
                                            </React.Fragment>
                                        )}
                                    </p>
                                    <small className='highlight fw-bolder'>{apartmentInfo.count}</small>
                                </div>
                            )}
                        </div>
                    </div>
                    {/* <div className='my-3' style={{ backgroundImage: `url(${mock})`, height: "650px", backgroundSize: "cover" }}></div> */}
                    <div>
                        <div className={activeProject.sector_plans?.length > 0 ? "d-block" : "d-none"} style={{ zIndex: 0 }}>
                            <ProjectMainPage projectId={projectId} />
                        </div>
                        {activeProject.project_category_id !== 3 &&
                            <>
                                <Link to="/elektron-xidmetler/satis-elanlari" className='d-flex justify-content-end my-2'>
                                    <h2 className='text-uppercase t-color5'>Satış elanları <img src={arrow} alt="Satış elanları" /></h2>
                                </Link>
                                {projectId !== 300018 &&
                                    <div className='row w-100 mx-0'>
                                        <div className='col-lg-7 col-12 px-5 pt-3 pb-5 block-bg-white'>
                                            <div className='d-flex justify-content-center'>
                                                <h2 className='text-uppercase t-color5 mb-5 mt-2'>Ödənİş üsulu</h2>
                                            </div>
                                            <p className='text-justify t-color6 mb-3' style={{ fontSize: "14px", lineHeight: "29px" }}>
                                                Vətəndaşlar mənzilləri üç ödəniş üsulu vasitəsilə əldə edə bilərlər:<br />
                                                1. Azərbaycan Respublikası İpoteka və Kredit Zəmanət Fondunun vəsaiti hesabına 30 ilədək uzunmüddətli illik 4%-lə güzəştli ipoteka krediti vasitəsilə;<br />
                                                2. Öz vəsaiti hesabına;<br />
                                                3. Digər kredit təşkilatından maliyyə vəsaiti cəlb etməklə.<br />
                                                Mənzil Azərbaycan Respublikası İpoteka və Kredit Zəmanət Fondunun vəsaiti hesabına güzəştli ipoteka krediti vasitəsilə alındıqda, minimal ilkin ödəniş məbləği mənzilin satış qiymətinin 10%-ni təşkil etməlidir.<br />
                                            </p>
                                            <div className='d-flex row w-100'>
                                                <div className='col-md-6 col-12'>
                                                    <small style={{ fontSize: "13px" }} className='t-color5 text-justify'>Mənzilə güzəştli ipoteka krediti hesabına sahib olmaq istəyən vətəndaş mənzilin satış qiymətinin 10%-dən daha çox ilkin ödənişi edərsə, artıq ödənilən məbləğə görə əlavə 10% endirim olunacaq.</small>
                                                    <div className='d-flex justify-content-between mt-3 mb-4 t-color5'>
                                                        <small style={{ fontSize: "13px" }}>Nümunə:</small>
                                                        <div className='p-1' style={{ backgroundColor: "#8ca2c233", width: "60%" }}>
                                                            <small style={{ fontSize: "11px" }}>
                                                                <span style={{ display: "flex", justifyContent: "space-between", marginBottom: "5px", marginTop: "5px" }}>
                                                                    <span>
                                                                        Mənzilin satış qiyməti:
                                                                    </span>
                                                                    <span>
                                                                        50 000 AZN
                                                                    </span>
                                                                </span>
                                                                <span style={{ display: "flex", justifyContent: "space-between", marginBottom: "5px", marginTop: "5px" }}>
                                                                    <span>
                                                                        İlkin ödəniş 10% olduqda:
                                                                    </span>
                                                                    <span>
                                                                        5 000 AZN
                                                                    </span>
                                                                </span>
                                                                <span style={{ display: "flex", justifyContent: "space-between", marginBottom: "5px", marginTop: "5px" }}>
                                                                    <span>
                                                                        İlkin ödəniş 40% olduqda:
                                                                    </span>
                                                                    <span>
                                                                        20 000 AZN
                                                                    </span>
                                                                </span>
                                                                <span style={{ display: "flex", justifyContent: "space-between", marginBottom: "5px", marginTop: "5px" }}>
                                                                    <span>
                                                                        Artıq ödənilən məbləğ:
                                                                    </span>
                                                                    <span>
                                                                        15 000 AZN
                                                                    </span>
                                                                </span>
                                                                <span style={{ display: "flex", justifyContent: "space-between", marginBottom: "5px", marginTop: "5px" }}>
                                                                    <span>
                                                                        Endirim olunan məbləğ:
                                                                    </span>
                                                                    <span>
                                                                        1 500 AZN
                                                                    </span>
                                                                </span>
                                                                <span style={{ display: "flex", justifyContent: "space-between", marginBottom: "5px", marginTop: "5px" }}>
                                                                    <span>
                                                                        Mənzilin son qiyməti:
                                                                    </span>
                                                                    <span>
                                                                        48 500 AZN
                                                                    </span>
                                                                </span>
                                                            </small>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className='col-md-6 col-12'>
                                                    <small style={{ fontSize: "13px" }} className='t-color5 text-justify'>Mənzilə öz vəsaiti hesabına sahib olmaq istəyən vətəndaş mənzilin satış qiymətinin 10% həcmində əlavə endirim əldə edəcək.</small>
                                                    <div className='d-flex justify-content-between pt-3 t-color5 mt-md-5 mb-4'>
                                                        <small style={{ fontSize: "13px" }}>Nümunə:</small>
                                                        <div className='p-1' style={{ backgroundColor: "#8ca2c233", width: "55%" }}>
                                                            <small style={{ fontSize: "11px" }}>
                                                                <span style={{ display: "flex", justifyContent: "space-between", marginBottom: "5px", marginTop: "5px" }}>
                                                                    <span>
                                                                        Mənzilin satış qiyməti:
                                                                    </span>
                                                                    <span>
                                                                        50 000 AZN
                                                                    </span>
                                                                </span>
                                                                <span style={{ display: "flex", justifyContent: "space-between", marginBottom: "5px", marginTop: "5px" }}>
                                                                    <span>
                                                                        Endirim məbləği 10%:
                                                                    </span>
                                                                    <span>
                                                                        5 000 AZN
                                                                    </span>
                                                                </span>
                                                                <span style={{ display: "flex", justifyContent: "space-between", marginBottom: "5px", marginTop: "5px" }}>
                                                                    <span>
                                                                        Mənzilin son qiyməti:
                                                                    </span>
                                                                    <span>
                                                                        45 000 AZN
                                                                    </span>
                                                                </span>
                                                            </small>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                            <small style={{ fontSize: "13px" }} className='t-color5 text-justify'>“Güzəştli mənzil” sistemində qeydiyyata alınaraq şəxsi elektron kabinetə sahib olmuş vətəndaşlar mənzilləri Azərbaycan Respublikası İpoteka və Kredit Zəmanət Fondunun müvəkkil bankları sırasına daxil olan və “MİDA” MMC ilə əməkdaşlıq müqaviləsi imzalayan kredit təşkilatları vasitəsilə əldə edə biləcəklər.</small>
                                        </div>
                                        <div className='col-lg-5 col-12 py-4 px-0 d-flex align-items-center' style={{ backgroundColor: "#8CA2C2" }}>
                                            <div className='d-flex justify-content-center align-items-center flex-wrap'>
                                                <a className='col-4 d-flex justify-content-center' href='https://ipoteka.kapitalbank.az/mortgage/government/item/8' target='_blank' rel="noreferrer"><img src={bank1} alt="Kapital Bank" /></a>
                                                <a className='col-4 m-4 d-flex justify-content-center' href='https://btb.az/az/individual/loans/mida-mmc-xetti-ile-social-ipoteka-10' target='_blank' rel="noreferrer"><img src={bank11} alt="Bank BTB" /></a>
                                                <a className='col-4 m-4 d-flex justify-content-center' href='https://afb.az/mida-xetti-ile-ipoteka' target='_blank' rel="noreferrer"><img src={bank12} className='w-100' alt="Bank AFB" /></a>
                                                <a className='col-4 m-4 d-flex justify-content-center' href='https://atb.az/mortrgages/mida-ipoteka-krediti/' target='_blank' rel="noreferrer"><img src={bank2} alt="ATB Bank" /></a>
                                                <a className='col-4 m-4 d-flex justify-content-center' href='https://vtb.az/personal/mortage/mida/' target='_blank' rel="noreferrer"><img src={bank3} alt="VTB Bank" /></a>
                                                <a className='col-4 m-4 d-flex justify-content-center' href='https://abb-bank.az/az/ferdi/kreditler/mida-ipoteka-krediti' target='_blank' rel="noreferrer"><img src={bank4} alt="ABB Bank" /></a>
                                                <a className='col-4 m-4 d-flex justify-content-center' href='https://www.turanbank.az/az/pages/22/465' target='_blank' rel="noreferrer"><img src={bank5} alt="Turan Bank" /></a>
                                                <a className='col-4 m-4 d-flex justify-content-center' href='https://ipoteka.pashabank.az/az/ipoteka/mida/details' target='_blank' rel="noreferrer"><img src={bank6} alt="Paşa Bank" /></a>
                                                <a className='col-4 m-4 d-flex justify-content-center' href='https://www.rabitabank.com/mida-xetti-ile-guzestli-ipoteka' target='_blank' rel="noreferrer"><img src={bank7} className='w-100' alt="Rabitə Bank" /></a>
                                                <a className='col-4 m-4 d-flex justify-content-center' href='https://www.bankofbaku.com/az/kreditler/ipoteka-krediti/148' target='_blank' rel="noreferrer"><img src={bank9} alt="Bank of Baku" /></a>
                                                <a className='col-4 m-4 d-flex justify-content-center' href='https://www.bankrespublika.az/az/ipoteka/mida-ipoteka-krediti_2584' target='_blank' rel="noreferrer"><img src={bank10} alt="Bank Respublika" /></a>
                                                <a className='col-3 m-2 d-flex justify-content-center' href='https://www.expressbank.az/credit/383#app' target='_blank' rel="noreferrer"><img src={bank13} alt="Express Bank" width={150} /></a>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </>}
                    </div>
                    <div id="multimedia" style={{ background: "#142237" }} className="py-3">
                        <div className='container'>
                            <div className='py-3'>
                                <h2 className='t-color4'>MULTİMEDİA</h2>
                            </div>

                            <div className="nav mb-2 pb-1 col-12 m-0 row">
                                {exterier.length > 0 &&
                                    <small className={"multimedia-tab py-2 px-4 me-3 " + (activeGalleryTab === 0 && "active")} onClick={_ => setActiveGalleryTab(0)}>
                                        <a className="nav-link">Eksteryer</a>
                                    </small>
                                }
                                {interier.length > 0 &&
                                    <small className={"multimedia-tab py-2 px-4 me-3 " + (activeGalleryTab === 1 && "active")} onClick={_ => setActiveGalleryTab(1)}>
                                        <a className="nav-link">İnteryer</a>
                                    </small>
                                }
                                {video.length > 0 &&
                                    <small className={"multimedia-tab py-2 px-4 me-3 " + (activeGalleryTab === 2 && "active")} onClick={_ => setActiveGalleryTab(2)}>
                                        <a className="nav-link">Video</a>
                                    </small>
                                }
                            </div>

                            <div className={" " + (activeGalleryTab === 0 ? "d-block" : "d-none")}>
                                {exterier &&
                                    <ProjectGallerySwiper>
                                        {exterier.map(item =>
                                            <SwiperSlide key={item.id} className='h-100'>
                                                {/* <ResizableImg src={Routing.generateFileRoute(item.file)} className="w-100 h-100" alt="..." /> */}

                                                <img src={Routing.generateFileRoute(item.file)} className='h-100 w-100' alt='' />

                                                <div className={"multimedia-info " + (showPhotoInfo === item.id ? "d-block" : "d-none")}>
                                                    <p className='ms-auto float-end' onClick={_ => setShowPhotoInfo(null)}><img src={close} alt="close" /></p>
                                                    <small dangerouslySetInnerHTML={{ __html: item.content_az }}></small>
                                                </div>
                                            </SwiperSlide>
                                        )}
                                        {/* <button className="multimedia-btn cursor" onClick={_ => setShowPhotoInfo(exterier.id)}>İnşaat materialları haqqında ətraflı məlumat</button> */}
                                    </ProjectGallerySwiper>}
                            </div>
                            <div className={" " + (activeGalleryTab === 1 ? "d-block" : "d-none")}>
                                {interier &&
                                    <ProjectGallerySwiper>
                                        {interier.map(item =>
                                            <SwiperSlide key={item.id} className='h-100 position-relative'>
                                                <img src={Routing.generateFileRoute(item.file)} className='h-100 w-100' alt="" />

                                                <div className={"multimedia-info " + (showPhotoInfo === item.id ? "d-block" : "d-none")}>
                                                    <p className='ms-auto float-end' onClick={_ => setShowPhotoInfo(null)}><img src={close} alt="close" /></p>
                                                    <small dangerouslySetInnerHTML={{ __html: item.content_az }}></small>
                                                </div>
                                            </SwiperSlide>
                                        )}
                                        {/* <button className="multimedia-btn cursor" onClick={_ => setShowPhotoInfo(interier.id)}>Təmir haqqında ətraflı məlumat</button> */}
                                    </ProjectGallerySwiper>}
                            </div>
                            <div className={" " + (activeGalleryTab === 2 ? "d-block" : "d-none")}>
                                {video[0] &&
                                    <div className='col-12'>
                                        <iframe className="d-flex justify-content-center col-10 m-auto" src={video[0].video_link} style={{ height: '450px' }} title={video[0].video_link}></iframe>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    );
}

export default ProjectPage;