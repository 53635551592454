import { IImage, generateImage } from "../../../../core/image";
import { IShape, shapeFromJson } from "../../../../components/editor/entites";
import { ERepairType } from "../../apartment/data/entities";
import { IMultiLang, multiLangFromJson } from "../../../../core/models";

export enum EProjectSectorStatus {
  Active = "active",
  Inactive = "inactive",
  Sold = "sold",
  Investor = "investor",
}

export interface IProjectSector {
  id: string;
  address: IMultiLang;
  shape: IShape;
  floorCount: number;
  name: string;
  studioCount: number;
  oneRoomCount: number;
  twoRoomCount: number;
  threeRoomCount: number;
  fourRoomCount: number;
  status: EProjectSectorStatus;
}

export const projectSectorFromJson = (json: any): IProjectSector => {
  const id = `${json.id}`;

  const e: IProjectSector = {
    id,
    address: multiLangFromJson(json, "address"),
    shape: shapeFromJson(json, id),
    floorCount: json.count_of_floors,
    name: json.name,
    studioCount: json.studio,
    oneRoomCount: json.oneRoom,
    twoRoomCount: json.twoRoom,
    threeRoomCount: json.threeRoom,
    fourRoomCount: json.fourRoom,
    status: json.status,
  };

  return e;
};

export interface IProject {
  image: IImage;
  sectors: IProjectSector[];
}

export const projectFromJson = (json: any): IProject => {
  const e: IProject = {
    image: generateImage(json),
    sectors: json.sectors.map(projectSectorFromJson),
  };

  return e;
};

export interface IPaginated<T> {
  list: T[];
  count: number;
  total: number;
  page: number;
  pageCount: number;
}

export const paginatedDataFromJson = <T>(json: any, dataFromJson: (j: any) => T): IPaginated<T> => {
  const e: IPaginated<T> = {
    list: json.data.map(dataFromJson),
    count: json.count,
    total: json.total,
    page: json.page,
    pageCount: Math.ceil(json.total / json.count),
  };

  return e;
};

export interface IProjectApartment {
  id: string;
  apartmentNumber: string;
  floorNumber: string;
  repairType: ERepairType;
  price: string;
  entrance: string;
  roomCount: string;
  externalSize: string;
  internalSize: string;
  sectorType: string;
  sectorName: string;
  buildingNumber: number;
}

export const projectApartmentFromJson = (json: any): IProjectApartment => {
  const e: IProjectApartment = {
    id: `${json.id}`,
    apartmentNumber: json.apartment_number,
    floorNumber: json.floor_number,
    repairType: json.repair_type,
    price: json.price || 0,
    entrance: json.entrance_number,
    roomCount: json.count_of_rooms,
    externalSize: json.external_size,
    internalSize: json.internal_size,
    sectorType: json.sectorType,
    sectorName: json.sectorName,
    buildingNumber: json?.building_number,
  };

  return e;
};

export interface IProjectApartmentFilter {
  projectPlanId: number,
  page: number;
  limit: number;
  data: {
    sectorTypes: { sectorType: string }[];
    floor: {
      from: string;
      to: string;
    };
    // floorFrom: string;
    // floorTo: string;
    roomCounts: { roomCount: string }[];
    repairTypes: { repairType: ERepairType }[];
  };
}

export const projectApartmentFilterToJson = (filter: IProjectApartmentFilter) => {
  return {
    projectPlanId: filter.projectPlanId,
    page: filter.page,
    limit: filter.limit,
    data: {
      sectorTypes: filter.data.sectorTypes,
      // floorFrom: filter.data.floorFrom,
      // floorTo: filter.data.floorTo,
      floor: {
        from: filter.data.floor.from,
        to: filter.data.floor.to,
      },
      roomCounts: filter.data.roomCounts,
      repairTypes: filter.data.repairTypes,
    },
  };
};
