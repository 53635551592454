import { BASE_URL } from "./api_client"

export const Routing = {
  // generateCreateRoute: (route: string): string => {
  //   return `${route}/create` 
  // },
  // generateEditRoute: (route: string, id = ":id"): string => {
  //   return `${route}/${id}/edit` 
  // },
  generateDetailRoute: (route: string, projectId = ":id", sectorId = ":id"): string => {
    return `${route}/${projectId}/${sectorId}`
  },
  // generateChildCreateRoute: (baseRoute: string, baseId = ":baseId", route: string): string => {
  //   return `${baseRoute}/${baseId}${route}/create` 
  // },
  // generateChildEditRoute: (baseRoute: string, baseId = ":baseId", route: string, id = ":id"): string => {
  //   return `${baseRoute}/${baseId}${route}/${id}/edit` 
  // },
  generateFileRoute: (file: any): string => {
    return !file ? "" : BASE_URL + "/" + file.directory + "/" + file.name
  }
} 
