import React, { useState } from "react";
import styled from "styled-components";
import { IAsyncData } from "../../../../../core/models";
import { IApartment } from "../../data/entities";
import Square from "../../../../../components/square";
import { InfoPanel } from "../../../../../components/info_panel/info_panel";
// import { TransparentButton } from "../../../../../components/transparent_button";
import { RepairTypeVideoModal } from "./repair_type_video_modal";
import { Link } from "react-router-dom";
import { ROUTES } from "../../../../../routes";

const InfoText = styled.p`
  margin-bottom: 5px;
  font-weight: 700;
  color: white;
  font-size: 1rem;

  a {
    color: black;
    /* opacity: 0.7; */
    font-weight: 800;

    :hover,
    :focus {
      color: black;
      /* opacity: 1; */
    }
  }
`;

const Bold = styled.p`
  font-weight: 700;
  font-size: 1rem;
`;

// const Title2 = styled.p`
//   color: white;
//   font-weight: 700;
//   font-size: 20px;
// `;

const StyledTitle = styled.p`
  font-weight: 700;
  font-size: 22px;
  text-align: center !important;
  color: white;
`;

// const RepairButton = styled(TransparentButton)`
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   margin-bottom: 1rem;
//   width: 100%;

//   p {
//     margin: 0;
//     margin-right: 10px;
//   }
// `;

interface IProps
  extends React.DetailedHTMLProps<
    React.HTMLAttributes<HTMLDivElement>,
    HTMLDivElement
  > {
  apartmentDetailBranch: IAsyncData<IApartment>;
}

export const ApartmentInfoPanel: React.FC<IProps> = ({
  apartmentDetailBranch,
  ...rest
}: IProps) => {
  const [isRepairTypeVideoModalOpen, setIsRepairTypeVideoModalOpen] =
    useState(false);

  // function openRepairTypeVideoModal() {
  //   setIsRepairTypeVideoModalOpen(true);
  // }

  function closeRepairTypeVideoModal() {
    setIsRepairTypeVideoModalOpen(false);
  }

  return (
    <InfoPanel {...rest}>
      <StyledTitle>
        <>
          {apartmentDetailBranch.data?.roomCount === 0 ? (
            <>Studio tipli mənzil</>
          ) : (
            <>{apartmentDetailBranch.data?.roomCount} otaqlı mənzil</>
          )}
        </>
      </StyledTitle>

      <div
        style={{
          width: "170px",
          height: "6px",
          background: "#223A5E",
          borderRadius: "22px",
          margin: "1rem auto",
        }}
      ></div>
      <>
        {/* <RepairButton onClick={openRepairTypeVideoModal}>
          <Title2>Təmir növü {apartmentDetailBranch.data?.repairType}</Title2>
          {/* <MdPlayCircleOutline size={24} color={theme.colors.blue} /> */}
        {/* </RepairButton>  */}
        

        <div className="d-flex justify-content-between">
          <InfoText>Xarici perimetr üzrə sahə</InfoText>
          <Bold>
            <Square square={apartmentDetailBranch.data?.externalSize} />
          </Bold>
        </div>

        <div className="d-flex justify-content-between">
          <InfoText>
            Daxili perimetr üzrə sahə <br /> <span style={{ fontSize: "10px" }}>(Dövlət qeydiyyatı sənədi üzrə)</span>
          </InfoText>
          <Bold>
            <Square square={apartmentDetailBranch.data?.internalSize} />
          </Bold>
        </div>

        <div className="d-flex justify-content-between">
          <InfoText>Mənzilin satış qiyməti</InfoText>
          <Bold>{apartmentDetailBranch.data?.price} AZN</Bold>
        </div>

        <div className="d-flex justify-content-between">
          <InfoText>
            <Link
              to={ROUTES.aboutDiscounts}
              style={{ color: "white", textDecoration: "underline" }}
            >
              Əlavə endirimdən
            </Link>{" "} sonra <br />
            mənzilin satış qiyməti
          </InfoText>
          <Bold>{apartmentDetailBranch.data?.discountPrice} AZN</Bold>
        </div>

        <RepairTypeVideoModal
          show={isRepairTypeVideoModalOpen}
          onHide={closeRepairTypeVideoModal}
        />
      </>
    </InfoPanel>
  );
};
