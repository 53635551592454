import React from "react";
import styled from "styled-components";
import { IFloorApartment } from "../../data/entities";
import Square from "../../../../../components/square";

const Wrapper = styled.div`
  border-radius: 5px;
  background-color: white;
  overflow: hidden;
  color: black;
  padding: 10px;
`;

const Title = styled.div`
  font-weight: 600;
  margin: 0;
`;

const Text = styled.div`
  font-weight: 600;
  margin: 0;
`;

interface IProps {
  apartment: IFloorApartment;
}

export const ApartmentInfo: React.FC<IProps> = ({ apartment }: IProps) => {
  return (
    <Wrapper>
      <Title>Mənzil {apartment.apartmentNumber}</Title>
      <Text>
        {apartment.roomCount == 0 ? "Studio" : apartment.roomCount + " otaqlı"}
      </Text>
      <Text>
        (<Square square={apartment.internalSize} />)
      </Text>
      {/* <Text>Təmir Növü {apartment.repairType}</Text> */}
    </Wrapper>
  );
};
