import { IImage, generateImage } from "../../../../core/image";
import { IShape, shapeFromJson } from "../../../../components/editor/entites";

export interface ISectorEtrance {
  name: string;
  oneRoomCount: number;
  twoRoomCount: number;
  threeRoomCount: number;
  fourRoomCount: number;
  studioCount: number;
}

export const sectorEtranceFromJson = (json: any): ISectorEtrance => {
  const e: ISectorEtrance = {
    name: json?.entrance_number,
    oneRoomCount: json.oneRoom,
    twoRoomCount: json.twoRoom,
    threeRoomCount: json.threeRoom,
    fourRoomCount: json.fourRoom,
    studioCount: json.studio
  };

  return e;
};

export interface ISectorFloor {
  id: string;
  number: string;
  shape: IShape;
  etrance: ISectorEtrance;
}

export const sectorFloorFromJson = (json: any): ISectorFloor => {
  const id = `${json.id}`;

  const e: ISectorFloor = {
    id,
    number: json.floor_number,
    shape: shapeFromJson(json, id),
    etrance: sectorEtranceFromJson(json?.count_of_apartments),
  };

  return e;
};

export interface ISector {
  image: IImage;
  name: string;
  floors: ISectorFloor[];
}

export const sectorFromJson = (json: any): ISector => {
  const e: ISector = {
    image: generateImage(json),
    name: json.sector_name,
    floors: json.floors.map(sectorFloorFromJson)
  };

  return e;
};
