import { createStore, applyMiddleware, combineReducers, Reducer } from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import thunk from "redux-thunk";
import { reduxPromiseMiddleware, confirmationMiddleware } from "./middlewares";
import { IProjectReduxState, ProjectReducers } from "../modules/projects/project/ui/state/state";
// import { IContactReduxState, ContactReducers } from "../modules/contact/ui/state/state";
import { ISectorReduxState, SectorReducers } from "../modules/projects/sector/ui/state/state";
import { IFloorReduxState, FloorReducers } from "../modules/projects/floor/ui/state/state";
import { ApartmentReducers, IApartmentReduxState } from "../modules/projects/apartment/ui/state/state";
// import { IAboutReduxState, AboutReducers } from "../modules/about/ui/state/state";
// import { IBenefitReduxState, BenefitReducers } from "../modules/projects/benefits/ui/state/state";
// import { IGalleryReduxState, GalleryReducers } from "../modules/gallery/ui/state/state";
// import { IConditionReduxState, ConditionReducers } from "../modules/conditions/ui/state/state";
// import { IHomeSliderReduxState, HomeSliderReducers } from "../modules/home/ui/state/state";

export interface IAppReduxState {
  project: IProjectReduxState;
  sector: ISectorReduxState;
  floor: IFloorReduxState;
  apartment: IApartmentReduxState;
  // sectorEntrance: ISectorReduxState;
  // contact: IContactReduxState;
  // about: IAboutReduxState;
  // benefit: IBenefitReduxState;
  // gallery: IGalleryReduxState;
  // condition: IConditionReduxState;
  // homeSlider: IHomeSliderReduxState;
}

export function getRootReducer(): Reducer<IAppReduxState> {
  const reducersList = {
    project: ProjectReducers,
    sector: SectorReducers,
    floor: FloorReducers,
    apartment: ApartmentReducers,
    // contact: ContactReducers,
    // about: AboutReducers,
    // benefit: BenefitReducers,
    // gallery: GalleryReducers,
    // condition: ConditionReducers,
    // homeSlider: HomeSliderReducers,
  };

  return combineReducers<IAppReduxState>(reducersList);
}

export const store = createStore(
  getRootReducer(),
  composeWithDevTools(applyMiddleware(thunk, reduxPromiseMiddleware, confirmationMiddleware)),
);
