import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IAppReduxState } from "../../../../../redux/store";
import { IAsyncData, Maybe, /*isNullish*/ } from "../../../../../core/models";
import { sectorRedux } from "../state/state";
import { ISector, ISectorEtrance } from "../../data/entities";
import { ROUTES } from "../../../../../routes";
import { ShapeViewer } from "../../../../../components/editor/shape_viewer/shape_viewer";
import { isSuccess } from "../../../../../core/redux";
import styled from "styled-components";
import { InfoPanel } from "../../../../../components/info_panel/info_panel";
import { EtranceInfo } from "../components/etrance_info";
// import { BackLink } from "../../../../components/back_link";
// import { RotateDetector } from "../../../../../components/rotate_detector";
// import { useTranslation } from "react-i18next";
import { FloorInfo } from "../components/floor_info";
import arrow_right from "../../../../../../assets/img/utils/arrow_right.svg";
import { useTranslation } from "react-i18next";
import { IProject, IProjectSector } from "../../../project/data/entities";
import { projectRedux } from "../../../project/ui/state/state";

const Wrapper = styled.div`
  height: 80vh;
  position: relative;
`;

// const Right = styled.div`
//   position: absolute;
//   top: 200px;
//   right: 0;
//   width: 300px;
// `;

const FloorNumber = styled.p`
  margin-bottom: 10px;
  color: white;
  text-align: center !important;
  font-size: 22px;
  font-weight: 700;
`;

export const SectorDetailPage: React.FC<any> = (props: any) => {
  // const { t } = useTranslation();
  const { projectId, sectorId, onPageChange } = props;

  const dispatch = useDispatch();
  const [floorNumber, setFloorNumber] = useState<Maybe<string>>(undefined);

  useEffect(() => {
    dispatch(sectorRedux.actions.getDetail(sectorId));
  }, [dispatch, sectorId]);
  const sectorDetailBranch = useSelector<IAppReduxState, IAsyncData<ISector>>(
    (state) => state.sector.details
  );

  useEffect(() => {
    dispatch(projectRedux.actions.getDetail(projectId));
  }, [dispatch, projectId]);
  const projectSectorBranch = useSelector<IAppReduxState, IAsyncData<IProject>>(
    (state) => state.project.details
  );

  const selectedFloorEtrances = sectorDetailBranch.data?.floors.find(
    (f) => f.number === floorNumber
  )?.etrance;

  const sector = projectSectorBranch.data?.sectors?.find(
    s => s.shape.id === sectorId
  );

  const { t, i18n } = useTranslation()
  const currentLang = i18n.language.slice(0, 2)

  return (
    // <RotateDetector>
    <Wrapper>
      {isSuccess(sectorDetailBranch) && (
        <>
          <div className="d-flex h-100">
            <InfoPanel title={`Giriş ${sectorDetailBranch.data?.name}`} address={projectSectorBranch.data?.sectors.find(x => x.id == sectorId)?.address[currentLang]}>
              <>
                <FloorNumber>
                  {floorNumber ? "Mərtəbə " + floorNumber : projectSectorBranch.data?.sectors.find(x => x.id == sectorId)?.floorCount + " Mərtəbəli"}
                </FloorNumber>
                <div
                  style={{
                    width: "170px",
                    height: "6px",
                    background: "#223A5E",
                    borderRadius: "22px",
                    margin: "1rem auto",
                  }}
                ></div>
                {selectedFloorEtrances && sector && (
                  <EtranceInfo etrance={selectedFloorEtrances} data={sector} />
                )}
              </>
            </InfoPanel>
            <ShapeViewer
              image={sectorDetailBranch.data?.image}
              shapes={sectorDetailBranch.data?.floors?.map(
                (floor: any) => floor.shape
              )}
              hoverColor="rgba(0, 38, 51, 0.5)"
              renderShapeLink={() => ""}
              renderShapeOnClickLink={(shape: any) => {
                const floor = sectorDetailBranch.data?.floors?.find(
                  (floor: any) => floor.shape.id === shape.id
                );
                onPageChange({
                  route: ROUTES.floor,
                  param1: sectorId,
                  param2: floor?.id,
                });
              }}
              renderHovered={(shape: any) => {
                const floor = sectorDetailBranch.data?.floors?.find(
                  (floor: any) => floor.shape.id === shape.id
                );
                return floor && <FloorInfo floor={floor} />;
              }}
              onHoveredChanged={(shape: any) => {
                const floor = sectorDetailBranch.data?.floors.find(
                  (floor: any) => floor.shape.id === shape?.id
                );
                setFloorNumber(floor?.number);
              }}
            />
          </div>

          <div
            className="btn-container d-flex justify-content-start my-3 position-absolute"
            style={{ top: "90%", left: "22%" }}
          >
            <div className="more-info-btn p-2" onClick={() => onPageChange({})}>
              <img
                className=""
                src={arrow_right}
                style={{ transform: "rotate(-180deg)" }}
                alt=""
              />
              <small>{t('common:go_back')}</small>
            </div>
          </div>
        </>
      )}

      {/* <BackLink href={ROUTES.project} /> */}
    </Wrapper>
    // </RotateDetector>
  );
};
